<template>
  <VRow column>
    <VCol style="flex: 1 1 auto">
      <DataViewUI
        hide-headers
        default-view-mode="grid"
        search-label="Search..."
        class="programs-index"
        :data="data"
        :filters="allFilters"
        :is-loading="activity.isLoading"
        :row-classes="false"
        :grid-padding="false"
        :rows-per-page="[12, 24, 48, 96]"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template slot="title">
          <span
            style="font-size: 30px; font-weight: 900;"
            :class="{
              'pb-4': $vuetify.breakpoint.smAndDown,
            }"
          >
            {{ featureName(modelType).capitalize() }}
          </span>
        </template>
        <template
          slot="action-right"
          slot-scope="props"
        >
          <VCol
            lg="2"
            md="2"
            sm="4"
            class="mr-1"
          >
            <VSelect
              v-model="sortBy"
              dense
              clearable
              outlined
              hide-details
              single-line
              height="36px"
              label="Sort"
              item-text="title"
              item-value="value"
              class="sortby-field"
              :items="sortItems"
              @change="props.loadData(true)"
            />
          </VCol>
          <VCol
            lg="auto"
            md="auto"
            sm="4"
          >
            <VTabs
              centered
              grow
              background-color="transparent"
              class="toggle-view"
            >
              <VTab @click="props.setViewMode('grid')">
                Tiles
              </VTab>
              <VTab @click="props.setViewMode('table')">
                List
              </VTab>
            </VTabs>
          </VCol>
          <VCol cols="auto">
            <ExpandableButton
              v-if="$isUserAdmin" 
              large
              class="wethrive"
              icon="add"
              :disabled="activity.isLoading"
              :tooltip-text="`Add ${featureName(modelType.capitalize(), 'singularize')}`"
              :action="toggleFormDialog"
            />
          </VCol>
        </template>
        <template
          slot="filter-button"
          slot-scope="props"
        >
          <VBtn
            depressed
            width="100%"
            style="border-radius: 10px; box-shadow: none; border: solid 1px rgba(0, 0, 0, 0.05);"
            @click="props.toggleFilterDrawer()"
          >
            <span
              class="pr-3"
              style="color: #808080; font-weight: 500; font-size: 14px;"
            >
              Filter by
            </span>
            <VSpacer />
            <VIcon
              small
              :color="hasFilter ? 'info' : 'default'"
            >
              filter_list
            </VIcon>
          </VBtn>
        </template>
        <template
          slot="card"
          slot-scope="props"
        >
          <IndexCard
            v-if="props.item.welcomeCard"
            welcome-card
            height="250px"
            :item="props.item"
            @onClick="onToggleFormDialog('form')" 
          />
          <IndexCard
            v-else
            height="250px"
            :item="props.item"
            :loading="props.item.isDuplicating"
            :disable-visibility="!$isUserAdmin"
            :locked="!$isUserAdmin && isMonetized(props.item)"
            :hide-menu="!$isUserAdmin && isMonetized(props.item)"
            :href="getHrefForItem(`${modelType}.view`, props.item.id) + '#dashboard'"
            :avatar-color="
              props.item.background_color
                ? props.item.background_color
                : '#fdbf14'
            "
            :alt-processing="props.item.processingDuplicate || (!props.item.is_duplication_complete && props.item.duplicated_using_id != null)"
            :processing="!props.item.is_duplication_complete && props.item.duplicated_on != null && !props.item.duplicated_using_id"
            @onClick="openDetailsDialog"
          >
            <template
              v-if="$isUserAdmin && isMonetized(props.item)"
              #before-title
            >
              <VTooltip top>
                <template #activator="{ on, attrs }">
                  <VIcon
                    v-on="on"
                    v-bind="attrs"
                    color="success"
                    class="mx-n1 mb-1"
                  >
                    mdi-currency-usd
                  </VIcon>
                </template>
                {{ `Monetized ${featureName(modelType).singularize().capitalize()}` }}
              </VTooltip>
            </template>
            <template
              v-if="!(!$isUserAdmin && isMonetized(props.item))"
              slot="link"
            >
              <Linkable
                :url="
                  getFullyQualifiedUrlForItem(
                    `${modelTypeRoute}.view`,
                    props.item.id
                  ) + '#dashboard'
                "
              />
            </template>
            <template slot="menu-items">
              <CardMenuItems
                :item="props.item"
                @setFormDialogModel="onSetFormDialogModel"
                @loadModelForSubmittedData="onLoadModelForSubmittedData"
                @duplicateItem="duplicateItem(props.item)"
                @deleteItem="onDeleteItem"
              />
            </template>
            <template #progress-content>
              <VRow class="fill-height">
                <VCol class="d-flex align-center px-8 py-0">
                  <span style="font-size: 14px; color: white; font-weight: 700;">
                    Importing Content...
                  </span>
                  <VSpacer />
                  <VTooltip
                    top
                    content-class="progress-tooltip"
                  >
                    <template #activator="{ on }">
                      <VIcon 
                        v-on="on"
                        color="#FFFFFF"
                      >
                        mdi-information-outline
                      </VIcon>
                    </template>
                    <div style="font-size: 14px;">
                      <span style="font-weight: 700;">Just a sec..</span>
                      <br>
                      <span style="font-weight: 400;">
                        {{ featureName(modelType, "singularize").capitalize() }} 
                        templates take a few minutes to fully import 
                        <br>
                        to your workspace. Please check back soon.
                      </span>
                    </div>
                  </VTooltip>
                </VCol>
              </VRow>
            </template>
          </IndexCard>
        </template>
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td
            :style="{
              position: 'initial !important',
              'box-shadow': 'none !important',
              ...bgClassess(props.item),
            }"
            @click="openDetailsDialog(props.item)"
          >
            <VAvatar
              size="40"
              :color="
                checkVisibility(props.item.id) ||
                  (!$isUserAdmin && isMonetized(props.item))
                  ? 'initial'
                  : props.item.background_color || '#fdbf14'
              "
              :style="{
                border:
                  checkVisibility(props.item.id) ||
                  (!$isUserAdmin && isMonetized(props.item))
                    ? '1px solid #ffffff'
                    : 'none',
              }"
            >
              <VIcon
                v-if="
                  checkVisibility(props.item.id) ||
                    (!$isUserAdmin && isMonetized(props.item))
                "
                style="color: #ffffff !important; z-index: 9;"
                :size="checkVisibility(props.item.id) ? 20 : 24"
              >
                {{ checkVisibility(props.item.id) ? "mdi-eye-off" : "lock" }}
              </VIcon>
              <span
                v-else
                class="white--text"
                style="font-size: 18px;"
              >
                {{
                  props.item.emoji || props.item.title.charAt(0).capitalize()
                }}
              </span>
            </VAvatar>
          </td>
          <td
            :style="{
              ...bgClassess(props.item),
            }"
            @click="openDetailsDialog(props.item)"
          >
            <template v-if="!$isUserAdmin && isMonetized(props.item)">
              <div
                class="text-truncate table-program-title"
                style="color: rgba(0, 0, 0, 0.87);"
              >
                {{ props.item.title }}
              </div>
            </template>
            <template v-else>
              <router-link :to="getHrefForItem(`${modelType}.view`, props.item.id)">
                <div
                  class="text-truncate table-program-title"
                  :style="{
                    color: checkVisibility(props.item.id)
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.87)',
                  }"
                >
                  {{ props.item.title }}
                  <Linkable
                    :url="
                      getFullyQualifiedUrlForItem(
                        `${modelTypeRoute}.view`,
                        props.item.id
                      )
                    "
                  />
                </div>
              </router-link>
            </template>
            <span v-if="props.item.content">
              <p
                v-linkified
                v-html="props.item.content"
                class="formatted program-content"
              />
            </span>
          </td>
          <td
            :style="bgClassess(props.item)"
            @click="openDetailsDialog(props.item)"
          >
            <span
              v-if="props.item.city"
              style="color: #000000; font-size: 14px;"
            >
              <VIcon size="13px">
                mdi-map-marker
              </VIcon>
              {{ props.item.city.name }}, {{ props.item.city.parent.name }}
            </span>
          </td>
          <td :style="bgClassess(props.item)">
            <AvatarGroup
              tooltip-text="Members"
              :preview-users="props.item.users"
              :total-users="
                props.item.students_count + props.item.mentors_count
              "
              :custom-style="{
                opacity:
                  checkVisibility(props.item.id) ||
                  (!$isUserAdmin && isMonetized(props.item))
                    ? 0.4
                    : 1,
              }"
              :href="
                !$isUserAdmin && isMonetized(props.item)
                  ? undefined
                  : prepareAvatarHref(props.item.id)
              "
              @showDetails="
                !$isUserAdmin && isMonetized(props.item)
                  ? openDetailsDialog(props.item)
                  : $router.push(prepareAvatarHref(props.item.id))
              "
            />
          </td>
          <td :style="bgClassess(props.item)">
            <VChip
              outlined
              style="color: #000000; font-size: 13px; font-weight: normal; min-width: 70px; background-color: transparent;"
              :href="
                !$isUserAdmin && isMonetized(props.item)
                  ? undefined
                  : prepareAvatarHref(props.item.id)
              "
              @click="
                !$isUserAdmin && isMonetized(props.item)
                  ? openDetailsDialog(props.item)
                  : $router.push(prepareAvatarHref(props.item.id))
              "
            >
              {{ props.item.mentors_count }}
              {{ featureName("Mentors", "pluralize") }}
            </VChip>
          </td>
          <td :style="bgClassess(props.item)">
            <VBtn
              v-if="!$isUserAdmin && isMonetized(props.item)"
              rounded
              outlined
              height="40px"
              width="40px"
              min-width="40px"
              style="z-index: 9; border: 1px solid #ffffff;"
              @click.native="openDetailsDialog(props.item)"
            >
              <VIcon
                size="24"
                color="#ffffff"
              >
                mdi-information-variant
              </VIcon>
            </VBtn>
            <CardMenu
              v-else
              :item="props.item"
              :disable-visibility="!$isUserAdmin"
              :enable-status-action="$isUserAdmin"
              :icon-color="checkVisibility(props.item.id) ? 'white' : 'inherit'"
              @visibilityStatus="onVisibilityStatus"
            >
              <template slot="additionalMenu">
                <CardMenuItems
                  :item="props.item"
                  @setFormDialogModel="onSetFormDialogModel"
                  @loadModelForSubmittedData="onLoadModelForSubmittedData"
                  @duplicateItem="duplicateItem(props.item)"
                  @deleteItem="onDeleteItem"
                />
              </template>
            </CardMenu>
          </td>
        </template>
      </DataViewUI>
      <FormDialog
        actions-text-color="#000000"
        :can-delete="false"
        :model-type="modelType"
        :disabled="isSkipDisabled"
        :hide-title="!currentModel"
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :enable-toggle-save="!currentModel"
        :hide-actions="currentCreateFromTab === 2"
        :new-actions="currentModel ? true : false"
        :width="currentModel ? '570px' : currentCreateFromTab === 3 ? '768px' : '1024px'"
        :background-color="currentModel ? '#FFFFFF' : '#F4F4F9'"
        :card-actions-class="currentModel ? null : currentCreateFromTab === 3 ? 'adjust-form-actions-alt' : 'adjust-form-actions'"
        :cancel-text="formCancelButtonText"
        :button-text="formSaveButtonText"
        :title="
          currentModel
            ? currentModel.title
            : `Add ${featureName(modelType.capitalize()).singularize()}`
        "
        @toggle:form:dialog="toggleFormDialog"
        @deleteItem="onDeleteItem"
      >
        <template 
          v-if="currentModel" 
          #before-title="{ props }"
        >
          <VSkeletonLoader
            type="avatar"
            class="mr-2"
            :loading="props.isFormLoading"
          >
            <EmojiAvatar
              :item="currentModel"
              @change="onChangeEmojiAvatar"
            />
          </VSkeletonLoader>
        </template>
        <template 
          v-if="currentCreateFromTab === 4"
          #footer-actions
        >
          <VBtn 
            large
            :disabled="isSkipDisabled"
            @click="$router.push({
              name: 'program.view',
              params: {
                id: $refs.createForm.form.id,
                team: $team.slug,
              },
              hash: '#dashboard',
            });"
          >
            <span class="black--text">Skip this</span>
          </VBtn>
          <VSpacer />
        </template>
        <Save
          v-if="currentModel"
          ref="saveForm"
          mode="dialog"
          :id="currentModel.id"
          @toggle:form:dialog="onToggleFormDialog"
        />
        <Create
          v-else
          ref="createForm"
          mode="dialog"
          @tabChanged="onTabChanged"
          @toggle:form:dialog="onToggleFormDialog"
          @processing="onProcessing"
        />
      </FormDialog>
      <FormDialog
        new-actions
        model-type="monetize"
        :can-delete="false"
        :max-width="$isUserAdmin ? '780px' : '375px'"
        :button-text="$isUserAdmin ? 'Save Changes' : 'Pay'"
        :hide-actions="hideMonetizeActions"
        :current-model="currentModel ? currentModel.product : null"
        :hide-delete-button="!$isUserAdmin || (currentModel && currentModel.product === null)"
        :is-dialog-open="dialogs.monetize"
        :title="
          $isUserAdmin
            ? `Save Payment Plan: ${currentModel ? currentModel.title : ''}`
            : 'Payment Details'
        "
        :do-save-action="$isUserAdmin ? null : () => $refs.monetizeNormal.checkout()"
        @toggle:form:dialog="onToggleFormDialog('monetize')"
        @deleteItem="(params) => $refs.monetize.onDeleteItem(params)"
      >
        <MonetizeAdmin
          v-if="$isUserAdmin"
          ref="monetize"
          mode="dialog"
          :current-model="currentModel"
          :id="
            currentModel && currentModel.product
              ? currentModel.product.id
              : null
          "
          @productAdded="onProductAdded"
          @productDeleted="onProductDeleted"
          @toggleActions="(hideActions) => (hideMonetizeActions = hideActions)"
          @toggleDialog="onToggleFormDialog('monetize')"
        />
        <MonetizeNormal
          v-else
          ref="monetizeNormal"
          mode="dialog"
          :id="
            currentModel && currentModel.product
              ? currentModel.product.id
              : null
          "
          :program-id="currentModel ? currentModel.id : null"
        />
      </FormDialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('Program')}`"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          :id="currentModel ? currentModel.id : null"
          ref="viewItem"
          mode="dialog"
        />
      </Dialog>
      <Dialog
        enable-footer-slots
        max-width="375px"
        card-class="title-border-none"
        :enable-cancel-button="false"
        :is-dialog-open="dialogs.details"
        @toggle:dialog="dialogs.details = !dialogs.details"
      >
        <template slot="title">
          <VCol>
            <VSkeletonLoader
              max-width="300"
              type="heading"
              class="mx-auto"
              :loading="detailsDialog.isLoading"
            >
              <h2
                v-if="detailsDialog.model"
                class="success--text pl-1"
              >
                {{ getPurchasePrice }}
              </h2>
            </VSkeletonLoader>
          </VCol>
        </template>
        <template slot="actions">
          <VBtn
            depressed
            width="35px"
            min-width="35px"
            height="35px"
            color="#ececf1"
            style="box-shadow: none;"
            :disabled="detailsDialog.isLoading"
            :loading="detailsDialog.isLoading"
            @click.native="dialogs.details = !dialogs.details"
          >
            <VIcon
              size="20px"
              color="#808080"
            >
              mdi-close-circle
            </VIcon>
          </VBtn>
        </template>
        <template slot="footer-right">
          <VBtn
            color="#60be39"
            class="mr-n2"
            style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25);"
            :disabled="detailsDialog.isLoading"
            :loading="detailsDialog.isLoading"
            @click="onSetFormDialogModel(detailsDialog.model, 'monetize')"
          >
            <span class="white--text">Pay Now</span>
          </VBtn>
        </template>
        <Details
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
          @updateModel="(result) => (detailsDialog.model = result)"
          @toggleLoading="(status) => (detailsDialog.isLoading = status)"
        />
      </Dialog>
      <Dialog
        max-width="580px"
        :dismissable="false"
        :is-dialog-open="dialogs.lockout"
        @toggle:dialog="dialogs.lockout = !dialogs.lockout"
      >
        <Lockout />
      </Dialog>
      <Dialog
        max-width="470px"
        :dismissable="false"
        :is-dialog-open="dialogs.billingSuccess"
        @toggle:dialog="dialogs.billingSuccess = !dialogs.billingSuccess"
      >
        <VRow class="pa-4">
          <VCol cols="12">
            <h1 
              class="font-weight-black"
              style="font-size: 28px;"
            >
              Success!
            </h1>
            <h3 
              class="text-body-1 py-4"
              style="color: #4F4F4F;"
            >
              Your <span class="font-weight-bold">{{ paidForPlan }}</span> is now active.  
            </h3>
            <VBtn
              block
              color="primary"
              height="54px"
              style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
              @click="onGetStarted"
            >
              <span 
                class="subtitle-1 font-weight-bold"
                style="color: #333333;"
              >
                  Great! Let’s Get Started
              </span>
            </VBtn>
          </VCol>
        </VRow>
      </Dialog>
      <Confirm ref="confirmDelete" />
      <Confirm
        ref="confirmDuplicate"
        confirm-color="info"
        cancel-color="disabled"
      />
      <Dialog
        max-width="640px"
        :is-dialog-open="submittedDialogs.reports"
        :title="`Submit ${featureName('Reports')}`"
        @toggle:dialog="onToggleSubmittedReportsDialog"
      >
        <SubmitReports
          mode="dialog"
          :items="submitted.data"
          :model-id="submittedModel.id"
          property="program_id"
        />
      </Dialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="submittedDialogs.deliverables"
        :title="`Submit ${featureName('Deliverables')}`"
        @toggle:dialog="onToggleSubmittedDeliverablesDialog"
      >
        <SubmitDeliverables
          mode="dialog"
          :items="submitted.data"
          :model-id="submittedModel.id"
          property="program_id"
        />
      </Dialog>
      <Dialog
        max-width="532px"
        :dismissable="false"
        :is-dialog-open="dialogs.freeTrialStart"
      >
        <FreeTrialStart @close="onFreeTrialClose" />
      </Dialog>
      <Alerts
        v-if="paymentAlert.show"
        :message-color="paymentAlert.color"
        :messages="[paymentAlert.message]"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import VisibleToMixin from "@/mixins/VisibleTo.vue";
import HasSubmittedData from "../../mixins/HasSubmittedData";
import SubmitDeliverables from "@/components/Deliverables/Submit";
import AvatarGroup from "@/components/Elements/Data/AvatarGroup";
import EmojiAvatar from "@/components/Elements/Data/EmojiAvatar";
import CardMenu from "@/components/Elements/Navigation/CardMenu";
import ViewComponent from "./View/";
import Save from "./Save";
import Create from "./Create/";
import IndexCard from "./Card";
import Lockout from "./Lockout";
import CardMenuItems from "./MenuItems";
import FreeTrialStart from "./FreeTrialStart";
import Details from "./Monetize/Details";
import MonetizeAdmin from "./Monetize/Admin";
import MonetizeNormal from "./Monetize/Normal";

export default {
  name: "ProgramsIndex",
  components: {
    Save,
    Create,
    EmojiAvatar,
    ViewComponent,
    SubmitDeliverables,
    IndexCard,
    Lockout,
    AvatarGroup,
    CardMenu,
    CardMenuItems,
    FreeTrialStart,
    Details,
    MonetizeAdmin,
    MonetizeNormal,
  },
  mixins: [SearchMixin, HasStats, HasSubmittedData, VisibleToMixin],
  data() {
    return {
      date: null,
      modelType: "program",
      programVisibleTo: [],
      hideVisibility: [],
      sortBy: null,
      sortItems: [],
      currentCreateFromTab: 0,
      formSaveButtonText: "Save Changes",
      formCancelButtonText: "Cancel",
      isSkipDisabled: false,
      detailsDialog: {
        model: null,
        isLoading: true,
      },
      dialogs: {
        monetize: false,
        details: false,
        freeTrialStart: false,
        lockout: false,
        billingSuccess: false,
      },
      paymentAlert: {
        show: false,
        color: "info",
        message: "",
      },
      hideMonetizeActions: false,
    };
  },
  watch: {
    currentModel: {
      handler: function (value) {
        if(value) {
          this.formCancelButtonText = "Cancel";
          this.formSaveButtonText = "Save Changes";
        } else {
          this.formCancelButtonText = "Cancel";
          this.formSaveButtonText = `Preview ${this.featureName(this.modelType.capitalize(), 'singularize')}`;
        }
      },
      immediate: true,
    },
    "data.data": {
      handler: function(value) {
        let welcomeCardIndex = value.findIndex((item) => item.hasOwnProperty("welcomeCard") && item.welcomeCard === true);
        if(welcomeCardIndex > -1 && value.length > 1) {
          this.$delete(this.data.data, welcomeCardIndex);
        } else if (value.length === 0) {
          this.data.data.push({
            title: `What’s a ${this.featureName(this.modelType, 'singularize').capitalize()}?`,
            content: `Great question, so glad you asked. ${this.featureName(this.modelType).capitalize()} are the central operating principle of WeThrive. For your programs, grants, etc etc, click below to start your first one.`,
            action: {
              text: `Create a New ${this.featureName(this.modelType, 'singularize').capitalize()}`,
            },
            welcomeCard: true,
          });
        }
      },
      deep: true,
    },
    paidForPlan() {
      if(this.$team.subscription_plan && this.$team.subscription_plan.hasOwnProperty("product") && this.$team.subscription_plan.product) {
        return this.$team.subscription_plan.product.title;
      }
      return "";
    },
  },
  created() {
    // Redirect user to Enterprise dashboard because unable to check these condtions in main.js i.e. redirectUsersToPrograms function due to null data.
    if (!this.$isUserApproved && this.$user.has_submitted_application) {
      this.$router.push({ name: "dashboard" });
    }
    this.$bus.$on("templateLoading", (status) => this.isSkipDisabled = status);
    if(this.$route.params.hasOwnProperty("welcome")) {
      this.$bus.$emit("notificationMessage", "Your Team Account has been created!", true);
      this.dialogs.freeTrialStart = true;
    }
    this.dialogs.lockout = !this.$team.is_free && this.$team.trialEnded && !this.$team.isValidSubscription;
    this.dialogs.billingSuccess = this.$route.query.hasOwnProperty("billing_status") && this.$route.query.billing_status == "success";
  },
  mounted() {
    if (this.$route.query.state) {
      if (this.$route.query.state == "success") {
        this.$set(this.paymentAlert, "message", "Payment done!");
      } else if (this.$route.query.state == "cancel") {
        this.$set(this.paymentAlert, "color", "error");
        this.$set(
          this.paymentAlert,
          "message",
          "Payment cancelled! Please try again."
        );
      }
      this.$set(this.paymentAlert, "show", true);
      this.$router.push({ name: "program.index" });
    }
  },
  beforeDestroy(){
    this.$bus.$off("templateLoading");
  },
  computed: {
    getPurchasePrice() {
      let minPriceItem = this.detailsDialog.model.product.items.reduce(
        (prev, cur) => (prev.price < cur.price ? prev : cur)
      );
      return `$${minPriceItem.price}+`;
    },
    paidForPlan() {
      if(this.$team.subscription_plan && this.$team.subscription_plan.hasOwnProperty("product") && this.$team.subscription_plan.product) {
        return this.$team.subscription_plan.product.title;
      }
      return "";
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "program/getIndexConfig",
      doGetPrograms: "program/getAll",
      doGetProgram: "program/getOne",
      doDeleteProgram: "program/delete",
      doSaveProgram: "program/save",
      doDuplicateProgram: "program/duplicate",
    }),
    onGetIndexConfig() {
      return this.doGetIndexConfig().then((result) => {
        Object.keys(result.sort).map((key) => {
          this.sortItems.push({ title: result.sort[key], value: key });
        });
        return result;
      });
    },
    onDelete(program) {
      return this.doDeleteProgram(program.id);
    },
    onDuplicate(params) {
      return this.doDuplicateProgram(params);
    },
    duplicateItem(item) {
      item.users = [];
      item.mentors_count = 0;
      item.students_count = 0;
      this.onDuplicateItem(item, null, null, true, true);
    },
    onGetAll(params) {
      params.perPage = 12;
      params.sort = this.sortBy;
      return this.doGetPrograms(params).then((result) => {
        result.data.forEach((item) => {
          this.hideVisibility.push({
            id: item.id,
            status:
              Array.isArray(item.visible_to) && item.visible_to.length === 0,
          });
        });
        // Handled duplication loading card
        if(!params.hasOwnProperty("page") || (params.hasOwnProperty("page") && params.page == 1)) {
          const pendingDuplications = this.getGroupItemLocalStorage("pending-duplications", this.modelType);
          if(pendingDuplications && Object.keys(pendingDuplications).length > 0) {
            Object.keys(pendingDuplications).map((itemKey) => {
              const itemIndex = result.data.findIndex((item) => item.duplicated_using_id == itemKey.split("-")[0]);
              if(itemIndex > -1) {
                this.$delete(pendingDuplications, itemKey);
                this.removeGroupItemLocalStorage("pending-duplications", this.modelType, itemKey);
              }
            });
          }
          this.$set(result, "data", [
            ...Object.values(pendingDuplications),
            ...result.data,
          ]);
        }
        return result;
      });
    },
    onGet(id) {
      return this.doGetProgram(id);
    },
    bgClassess(item) {
      return {
        "border-bottom": "thin solid rgba(0, 0, 0, 0.12) !important",
        "background-color":
          this.checkVisibility(item.id) ||
          (this.isMonetized(item) && !this.$isUserAdmin)
            ? "rgba(47, 53, 63, 0.75) !important"
            : "transparent !important",
        "backdrop-filter":
          this.checkVisibility(item.id) ||
          (this.isMonetized(item) && !this.$isUserAdmin)
            ? "blur(15px)"
            : "blur(0px)",
        cursor:
          this.isMonetized(item) && !this.$isUserAdmin ? "pointer" : "initial",
      };
    },
    checkVisibility(id) {
      let filteredItem = this.hideVisibility.filter((item) => {
        return item.id == id && item.status === true;
      });
      return filteredItem.length > 0;
    },
    onVisibilityStatus(status, item) {
      this.hideVisibility.map((program) => {
        program.status =
          program.id == item.id ? (program.status = status) : program.status;
      });
    },
    isMonetized(item) {
      return this.isProgramMonetized(item);
    },
    onProductAdded(programId, product) {
      let index = this.data.data.map((item) => item.id).indexOf(programId);
      this.$set(this.data.data[index], "product", product);
    },
    onProductDeleted(programId) {
      let index = this.data.data.map((item) => item.id).indexOf(programId);
      this.$set(this.data.data[index], "product", null);
    },
    prepareAvatarHref(itemId) {
      return (this.getHrefForItem(`${this.modelType}.view`, itemId) + "#community");
    },
    openDetailsDialog(item) {
      if (!this.$isUserAdmin && this.isMonetized(item)) {
        this.$set(this.detailsDialog, "model", item);
        this.$set(this.detailsDialog, "isLoading", true);
        if(this.detailsDialog.model && this.detailsDialog.model.product.items_count > 1) {
          this.onSetViewDialogModel(item, "details");
        } else {
          this.onSetFormDialogModel(this.detailsDialog.model, 'monetize');
        }
      }
    },
    onChangeEmojiAvatar(emoji, color) {
      this.$refs.saveForm.form.background_color = color;
      this.$refs.saveForm.form.emoji = emoji;
    },
    toggleFormDialog() {
      if(!this.currentModel && this.$refs.hasOwnProperty("createForm") && this.$refs.createForm) {
        if(this.$refs.createForm.hasOwnProperty("activeTab") && this.$refs.createForm.activeTab) {
          this.$refs.createForm.activeTab--;
        } else {
          this.onToggleFormDialog();  
        }
      } else {
        this.onToggleFormDialog();
      }
    },
    onProcessing() {
      this.$set(this.data.data[0], 'duplicated_on', true);
      this.$refs.createForm.activeTab = 0;
      this.currentCreateFromTab = 0;
    },
    onTabChanged(index) {
      this.currentCreateFromTab = index;
      if(index === 4) {
        this.formCancelButtonText = "Go Back";
        this.formSaveButtonText = "Use this Template";
      } else if(index === 3) {
        this.formCancelButtonText = "Go Back";
        this.formSaveButtonText = "Continue";
      } else if(index === 1) {
        this.formCancelButtonText = "Go Back & Edit";
        this.formSaveButtonText = `Create ${this.featureName(this.modelType, 'capitalize').singularize()}`;
      } else {
        this.formCancelButtonText = "Cancel";
        this.formSaveButtonText = `Preview ${this.featureName(this.modelType.capitalize(), 'singularize')}`;
      }
    },
    onFreeTrialClose() {
      this.dialogs.freeTrialStart = false;
    },
    onGetStarted() {
      this.dialogs.billingSuccess = false;
      this.$confetti.start();
      setTimeout(() => {
        this.$confetti.stop();
      }, 5000);
    },
  },
};
</script>

<style lang="scss">
.programs-index {
  background-color: transparent !important;
  p {
    margin: 0px !important;
  }
  .v-card__title {
    border-bottom: none !important;
  }
  .search-field {
    width: 375px;
    height: 36px;
    flex: none;

    .v-input__prepend-outer {
      display: none;
    }
    .v-input__append-outer {
      margin: 0px 14px !important;
    }
    .v-input__slot {
      min-height: 36px !important;
    }
    .v-label {
      color: #808080 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
  }
  .sortby-field {
    .v-select__selection--comma {
      font-size: 14px;
    }
    .v-input__slot {
      min-height: 36px !important;
    }
    .v-label {
      color: #808080 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
  }
  .program-content {
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
  }
  .data-table {
    border-radius: 10px !important;
    tbody tr {
      box-shadow: none !important;
    }
    tbody tr:nth-child(even) {
      background-color: white !important;
    }
    tbody
      > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: white !important;
    }
  }
  .data-view-title {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .toggle-view {
    .v-tabs-bar__content {
      height: 36px !important;
    }
  }
  .table-program-title {
    width: 500px;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.5;
  }
}
.adjust-form-actions-alt {
  padding: 8px 144px 8px 148px !important;
  box-shadow: 0px -10px 15px 0px #0000000F;
  .v-btn {
    padding: 11px 24px !important;
    .v-btn__content {
      font-size: 14px !important;
      font-weight: 600;
    }
  }
}
.adjust-form-actions {
  padding: 8px 232px 8px 228px !important;
  box-shadow: 0px -10px 15px 0px #0000000F;
  .v-btn {
    padding: 11px 24px !important;
    .v-btn__content {
      font-size: 14px !important;
      font-weight: 600;
    }
  }
}
.progress-tooltip {
  font-size: 14px !important;
  text-align: center;
  box-shadow: 0px 3px 7px 0px #00000026 !important;
}
</style>
