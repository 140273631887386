<script>
import FormMixin from "@/mixins/Form";
export default {
  mixins: [FormMixin],
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modelType: "Program",
    };
  },
  methods: {
    onSave() {
      this.$emit("save");
    },
  },
}
</script>