<template>
  <div>
    <VTabs
      v-model="activeTab"
      grow
      lazy
      slider-color="primary"
      class="d-none"
    >
      <VTab
        v-for="(tab, key) in tabs"
        lazy
        :key="key"
      >
        {{ tab.title }}
      </VTab>
    </VTabs>
    <VTabsItems
      v-model="activeTab"
      touchless
      id="create-program"
      :style="{
        margin: tabMargin,
      }"
    >
      <VTabItem 
        v-for="(tab, index) in tabs" 
        :key="index" 
      >
        <component
          :ref="`${tab.name}Tab`"
          :is="tab.name"
          :item="form"
          :active-tab="activeTab"
          @save="onSave"
          @resetForm="onResetForm"
          @changeCurrentTab="(index) => activeTab = index"
          @formDetails="(details) => form = details"
          @toggle:form:dialog="$emit('toggle:form:dialog')"
        />
      </VTabItem>
    </VTabsItems>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import Form from "./Form";
import Preview from "./Preview";
import Progress from "./Progress";
import Templates from "./Templates";
import TemplateDetails from "./TemplateDetails";
export default {
  name: "ProgramsCreate",
  mixins: [FormMixin],
  components: {
    Form,
    Preview,
    Progress,
    Templates,
    TemplateDetails,
  },
  data() {
    return {
      modelType: "Program",
      activeTab: 0,
      form: {},
    };
  },
  watch: {
    activeTab(value) {
      this.$emit("tabChanged", value);
    },
  },
  computed: {
    tabs() {
      return [
        {
          enabled: true,
          name: "Form",
          title: "Form",
        },
        {
          enabled: true,
          name: "Preview",
          title: "Preview",
        },
        {
          enabled: true,
          name: "Progress",
          title: "Progress",
        },
        {
          enabled: true,
          name: "Templates",
          title: "Templates",
        },
        {
          enabled: true,
          name: "TemplateDetails",
          title: "Template Details",
        },
      ].filter((t) => t.enabled);
    },
    tabMargin() {
      if(this.activeTab === 0) {
        return "0px 148px";
      } else if(this.activeTab === 1) {
        return "0px 60px"
      } else {
        return "0px";
      }
    },
  },
  methods: {
    ...mapActions({
      doSaveProgram: "program/save",
      doCreateTemplate: "template/create",
      doUseTemplate: "template/copyContent",
    }),
    onResetForm() {
      this.$refs.FormTab[0].resetForm();
      this.$refs.FormTab[0].$refs.emojiAvatar.reset();
    },
    onSave() {
      if(this.activeTab === 4) {
        this.useTemplate();
      } else if(this.activeTab === 3) {
        this.createTemplate();
      } else if(this.activeTab === 1) {
        this.createProgram();
      } else {
        this.activeTab = 1;
      }
    },
    useTemplate() {
      const data = {
        type: "program",
        program_id: this.form.id,
        identifier: this.form.id,
        template_id: this.form.template.id,
      };
      this.$bus.$emit("templateLoading", true);
      this.saveFormModel(this.doUseTemplate, null, data, null, false)
        .then((result) => {
          if(result) {
            this.$emit("processing", this.form.id); 
            this.$nextTick(() => {
              this.$emit("toggle:form:dialog");
            });
          }
        });
    },
    createTemplate() {
      if(this.form.selectedTemplate > 0) {
        this.activeTab = 4;
      } else {
        const data = {
          type: "programs",
          hub_type: {
            title: this.form.customTemplate,
          },
        };
        const programId = this.form.id;
        this.$bus.$emit("templateLoading", true);
        this.saveFormModel(this.doCreateTemplate, null, data, null, false)
          .then(() => {
            this.$router.push({
              name: "program.view",
              params: {
                id: programId,
                team: this.$team.slug,
              },
              hash: "#dashboard",
            });
          });
      }
    },
    createProgram() {
      const data = this.prepareFormModel(
        { 
          ...this.form, 
          forPreview: 1 
        },
        {
          dontSave: ["calendars", "city", "user", "threads"],
        }
      );
      this.saveFormModel(this.doSaveProgram, null, data, null, false).then(
        (result) => {
          if(result) {
            this.$set(this.form, "id", result.id);
            // Switch to Progress tab
            this.activeTab = 2;
            this.$bus.$emit(
              "notificationMessage",
              `Let’s Go! Your new ${ this.featureName(this.modelType, "singularize") } has been created.`
            );
          }
        }
      );
    },
  },
}
</script>
<style lang="scss">
#create-program {
  padding: 24px 70px;
  background-color: transparent;

  .v-text-field__slot, .v-select__slot {
    input::placeholder {
      font-style: italic;
      font-size: 14px;
      font-weight: 500;
      color: #808080;
    }
  }
  .error--text:not(.no-border) .v-input__slot {
    border-radius: 14px;
  }
  .v-input:not(.v-input--checkbox):not(.v-input--radio):not(.v-input--switch) fieldset {
    border-radius: 14px;
  }
  .v-input:not(.v-input--checkbox):not(.v-input--radio):not(.v-input--switch):not(.error--text) fieldset {
    border: 1px solid #CCCCCC;
  }
  .content-editor .editor__content > div {
    border: solid 1px #CCCCCC;
    height: 135px !important;
    border-radius: 14px;
  }
  .main-title {
    font-size: 32px;
    font-weight: 900;
    line-height: 43.2px;
  }
  .main-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24.3px;
  }
  .main-subtitle-alt {
    color: #808080;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.3px;
  }
  .counter-text {
    font-size: 12px;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .editor {
    p.is-empty:first-child::before {
      font-style: italic;
      font-weight: 500;
      color: #808080;
    }
  }
}
</style>