<template>
  <VContainer
    fluid
    grid-list-lg
    fill-height
    class="pa-0"
  >
    <VRow
      v-if="modelHasId"
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <ViewHeader />
      </VCol>
      <VCol
        cols="12"
        class="py-0"
        style="flex: 1 1 auto"
      >
        <VCard class="details-dialog">
          <VCardText class="py-0">
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Program", "singularize") }} Title
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <h3 style="font-size: 18px;">
                  {{ model.title }}
                </h3>
              </VCol>
            </VRow>
            <VRow v-if="model.content">
              <VCol cols="12">
                <span class="label">
                  Description
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <p
                  v-linkified
                  v-html="model.content"
                  class="formatted content"
                  :style="{
                    'line-height': 1.4, 
                    'color': '#000000',
                    'font-size': '14px'
                  }"
                />
              </VCol>
            </VRow>
            <VRow v-if="model.lessons.length > 0">
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Lessons") }} Included
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <ChipsList
                  show-avatar
                  model-type="lessons"
                  :enable-action="false"
                  :closable="false"
                  :items="model.lessons.slice(0, 3)"
                  :show-more-count="model.lessons.length - 3"
                />
              </VCol>
            </VRow>
            <VRow v-if="model.mentors_count">
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Mentors") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <div
                  v-for="(mentor, index) in model.mentors"
                  style="display: inline-grid;"
                  :key="index"
                >
                  <UserModal
                    custom-class="mr-3 mb-3 members"
                    :enable-info-dialog="false"
                    :user="mentor"
                  />
                </div>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Students") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <AvatarGroup 
                  tooltip-text="Members"
                  :preview-users="model.students"
                  :total-users="model.students_count"
                />
              </VCol>
            </VRow>
            <VRow v-if="model.product.faqs.length > 0">
              <VCol cols="12">
                <span class="label">
                  {{ featureName("FAQs") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <VExpansionPanels accordion>
                  <VExpansionPanel
                    v-for="item in model.product.faqs"
                    style="box-shadow: 0 0.5px 0 0 #ececf1;"
                    :key="item.id"
                  >
                    <VExpansionPanelHeader style="color: rgb(37, 37, 38); font-size: 14px; font-weight: 900;">
                      <span class="text-truncate">
                        {{ item.title }}
                      </span>
                    </VExpansionPanelHeader>
                    <VExpansionPanelContent class="pt-0">
                      <p
                        v-linkified
                        v-html="item.content"
                        class="formatted content"
                        :style="{
                          'line-height': 1.4, 
                          'color': '#808080',
                          'font-size': '14px'
                        }"
                      />
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>    
</template>
<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";
import AvatarGroup from "@/components/Elements/Data/AvatarGroup";

export default {
  name: "ProgramDetails",
  mixins: [ViewMixin],
  components: {
    ViewHeader,
    AvatarGroup,
  },
  watch: {
    modelHasId(value) {
      this.$emit("toggleLoading", !value);
    },
  },
  methods: {
    ...mapActions({
      doGetProgram: "program/getOne",
    }),
    onGet(id) {
      return this.doGetProgram(id).then((result) => {
        if(this.$isUserStudent && result.data.lessons instanceof Object) {
          result.data.lessons = [
            ...result.data.lessons.all.data,
            ...result.data.lessons.overdue.data,
            ...result.data.lessons.started_not_complete.data,
          ];
        }
        this.$emit("updateModel", result.data);
        return result;
      });
    },
  },
};
</script>
<style lang="scss">
.details-dialog {
  p {
    margin: 0px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 12px 12px !important;
  }
  .members .v-chip__content {
    color: #252526;
    font-weight: 500;
  }
}
</style>