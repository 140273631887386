var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","type":"table-heading,card","loading":_vm.isLoading}},[_c('VRow',{attrs:{"id":"template-details"}},[_c('VCol',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"main-title"},[_vm._v(" Use Our "+_vm._s(_vm.item.template.title)+" Program Template ")]),_c('h2',{staticClass:"main-subtitle-alt",staticStyle:{"padding":"0px 168px"}},[_vm._v(" Great news: this is our bread & butter. We have a quick-start template for "+_vm._s(_vm.item.template.title)+" Programs to help you get up and running ASAP. ")])]),_c('VCol',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-black title"},[_vm._v(" Here’s What You’ll Get ")])]),_c('VCol',{style:({
        padding: _vm.templateItems.length > 4 ? 'auto' : '0px 18%',
      }),attrs:{"cols":"12"}},[_c('VRow',{attrs:{"justify":"center"}},_vm._l((_vm.templateItems),function(templateItem,index){return _c('VCol',{key:index,staticClass:"py-4",attrs:{"cols":_vm.templateItems.length > 4 ? 4 : 6}},[_c('VCard',{staticStyle:{"box-shadow":"0px 5px 25px 10px rgba(0, 0, 0, 0.02)"},attrs:{"height":"100%","min-height":"188px"}},[_c('VCardTitle',{staticClass:"border-none justify-center"},[_c('VCol',{staticClass:"text-center"},[_c('VAvatar',{staticStyle:{"top":"-24px"},attrs:{"size":"90","color":templateItem.backgroundColor}},[_c('img',{style:({
                      height: templateItem.height,
                      width: templateItem.width,
                      objectFit: 'contain',
                    }),attrs:{"src":templateItem.icon}})])],1)],1),_c('VCardText',{staticClass:"text-center"},[_c('VRow',[_c('VCol',{staticClass:"title font-weight-bold py-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(templateItem.title)+" ")]),_c('VCol',{ref:`${templateItem.name}-description`,refInFor:true,staticClass:"description pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.getDescription(templateItem.items))+" ")]),(templateItem.hasMore)?_c('VCol',{attrs:{"cols":"12"}},[_c('VTooltip',{attrs:{"right":"","max-width":"340px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('VChip',_vm._g({staticStyle:{"font-size":"18px"},attrs:{"color":"#ECECF1","text-color":"#000000"}},on),[_vm._v(" View More ")])]}}],null,true)},[_c('ol',_vm._l((templateItem.items),function(item,key){return _c('li',{key:key},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])],1):_vm._e()],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }