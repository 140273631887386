<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isLoading"
  >
    <VRow 
      id="programs-templates" 
      class="justify-center"
    >
      <VCol
        cols="12"
        class="text-center"
      >
        <h1 class="main-title">
          Build Out Your New {{ featureName(modelType, "singularize") }}
        </h1>
        <h2 class="main-subtitle-alt">
          Start shaping your space by choosing a program type—mentorship, education, competition, or another—and we'll personalize your {{ featureName(modelType, "singularize") }}'s setup to your context.
        </h2>
      </VCol>
      <VCol cols="12">
        <VRow class="justify-center">
          <VCol 
            cols="auto"
            class="font-weight-black title"
          >
            What kind of program is this?
          </VCol>
          <VCol cols="7">
            <VRadioGroup
              v-model="form.selectedTemplate"
              column
              hide-details 
              active-class="active-radio"
              class="mt-1 ml-2"
            > 
              <VRadio
                v-for="(template, index) in templates"
                color="#000000"
                class="custom-radio py-1"
                :key="index"
                :label="template.title"
                :value="template.id"
              />
              <VRadio
                color="#000000"
                label="Other"
                value="0"
                class="custom-radio py-1"
              />
              <TextField
                v-if="form.selectedTemplate == 0"
                v-model="form.customTemplate"
                hide-details
                placeholder="Do tell"
                class="ml-10"
                style="width: 270px;"
              />
            </VRadioGroup>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import CreateProgramMixin from "@/mixins/CreateProgram";
export default {
  name: "ProgramsTemplates",
  mixins: [CreateProgramMixin],
  data() {
    return {
      templates: [],
      defaultForm: {
        customTemplate: null,
        selectedTemplate: null,
      },
    };
  },
  validations: {
    form: {
      selectedTemplate: {
        required,
      },
      customTemplate: {
        required: requiredIf(function (value) { 
          return value.selectedTemplate == 0;
        }),
      },
    },
  },
  watch: {
    form: {
      handler: function(value) {
        this.$emit("formDetails", {
          ...this.item,
          ...value,
          template: this.templates.find((template) => template.id === value.selectedTemplate),
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getTemplates();
    this.$v.$touch();
  },
  methods: {
    ...mapActions({
      doGetTemplates: "template/getAll",
    }),
    getTemplates() {
      this.emitLoading();
      this.doGetTemplates({ type: "program"})
        .then((result) => {
          this.templates = result.data;
          this.emitLoadingDone();
        });
    },
  },
}
</script>
<style lang="scss">
#programs-templates {
  padding: 0px 70px;

  .v-radio > .v-label {
    font-size: 20px;
    color: #000000;
  }
  .active-radio .v-label {
    font-weight: 700;
  }
  .v-input--selection-controls__input {
    margin-right: 20px;
    background-color: white;
    border: solid thin white !important;
  }
  .v-icon.mdi.mdi-radiobox-blank {
    background-color: #ffffff;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .v-icon.mdi.mdi-radiobox-marked {
    background-color: #ffffff;
    color: #ffffff;
    caret-color: #ffffff;
  }
}
</style>