<template>
  <div>
    <VDivider v-if="$isUserAdmin" />
    <VList class="pt-2">
      <VListItem
        v-if="$isUserAdmin"
        @click="$emit('setFormDialogModel', item)"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          mdi-square-edit-outline
        </VIcon>
        <VListItemTitle>Edit</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="isEnabledFeature('monetization') && $isUserAdmin"
        @click="$emit('setFormDialogModel', item, 'monetize')"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          mdi-currency-usd
        </VIcon>
        <VListItemTitle>
          Monetize
          <BetaBadge
            enable-tooltip
            inline
            right
            style="margin-top: 0px"
            :is-beta="isBetaFeature('monetization')"
          />
        </VListItemTitle>
      </VListItem>
      <VListItem
        @click="$emit('loadModelForSubmittedData', item, 'deliverables')"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          save
        </VIcon>
        <VListItemTitle>
          Submit {{ featureName("Deliverables") }}
        </VListItemTitle>
      </VListItem>
      <VListItem
        v-if="$isUserAdmin"
        @click.prevent="$emit('duplicateItem', item, null, null, true, true)"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          content_copy
        </VIcon>
        <VListItemTitle>Duplicate</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="$isUserAdmin"
        @click="$emit('deleteItem', item)"
      >
        <VIcon
          size="20px"
          color="error"
          class="pr-2"
        >
          mdi-delete
        </VIcon>
        <VListItemTitle style="color: #ff5252;">
          Delete Forever
        </VListItemTitle>
      </VListItem>
    </VList>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
};
</script>
