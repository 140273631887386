<template>
  <VRow id="preview-program">
    <VCol
      cols="12"
      class="text-center"
    >
      <h1 class="main-title">
        Preview Your {{ featureName(modelType, "singularize") }}
      </h1>
      <h2 
        class="main-subtitle"
        style="padding: 0px 132px;"
      >
        This is how your {{ featureName(modelType, "singularize") }} will look to your {{ featureName("mentors", "capitalize") }} and {{ featureName("students", "capitalize") }} in the WeThrive application.
      </h2>
    </VCol>
    <VCol
      cols="12"
      class="text-center"
    >
      <h3 style="font-size: 16px;">
        {{ featureName(modelType, "singularize") }} Card on Your Team Homepage
      </h3>
    </VCol>
    <VCol
      cols="12"
      class="d-flex justify-center pt-0"
      style="position: relative;"
    >
      <VOverlay
        absolute
        light
        color="transparent"
        opacity="0"
        class="card-overlay"
      />
      <Card
        disable-visibility
        only-visibility-menu
        width="327px"
        height="250px"
        :href="null"
        :item="item"
        :avatar-color="item.background_color ? item.background_color : '#fdbf14'"
        :card-style="{
          'width': '327px',
          'cursor': 'default',
          'box-shadow': '0px 4px 15px 0px #00000026',
        }"
      >
        <template #link>
          <Linkable />
        </template>
      </Card>
    </VCol>
    <VCol cols="12">
      <h3 style="font-size: 16px;">
        {{ featureName(modelType, "singularize") }} Main View
      </h3>
      <VContainer
        fill-height
        class="view-program ma-0 px-6 mt-6 py-0 mb-n8"
        style="box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15); position: relative; border-radius: 10px;"
      >
        <VRow 
          class="align-center"
          style="cursor: default;"
        >
          <VCol cols="auto">
            <VAvatar
              size="49"
              :color="item.background_color ? item.background_color : 'primary'"
            >
              <span
                v-if="item.emoji"
                style="font-size: 32px;"
              >
                {{ item.emoji }}
              </span>
              <b
                v-else
                class="white--text"
                style="font-size: 16px;"
              >
                {{ item.title.charAt(0).capitalize() }}
              </b>
            </VAvatar>
          </VCol>
          <VCol
            cols="auto px-0"
            style="max-width: 50%;"
          >
            <VListItemTitle
              :style="{
                'line-height': '1.4',
                'font-weight': 900,
                'font-size': '21px',
              }"
            >
              {{ item.title }}
            </VListItemTitle>
            <VListItemSubtitle
              v-if="item.city_id && item.city"
              style="color: #808080; font-size: 11px;"
            >
              <VIcon size="14px">
                mdi-map-marker
              </VIcon>
              {{ item.city.name.trim() }}, {{ item.city.parent.name }}
            </VListItemSubtitle>
          </VCol>
        </VRow>
        <VRow 
          v-if="item.content" 
          class="mt-n2"
          style="cursor: default;"
        >
          <VCol class="py-2">
            <p
              v-linkified
              v-html="item.content"
              class="formatted content"
              :style="{
                'line-height': 1.4,
                color: '#808080',
                'font-size': '12px',
              }"
            />
          </VCol>
        </VRow>
        <VRow style="position: relative;">
          <VCol 
            cols="12" 
            class="py-0"
          >
            <VOverlay
              absolute
              light
              color="transparent"
              class="main-view-overlay"
            />
            <VRow class="py-4 mx-0">
              <VCol
                cols="12"
                class="d-flex px-0"
              >
                <span 
                  class="label-text" 
                  style="font-size: 16px;"
                >
                  {{ featureName("Pipelines") }}
                </span>
                <VSpacer />
                <TextField
                  single-line
                  hide-details
                  append-icon="refresh"
                  label="Search..."
                  class="search-field shrink mr-2"
                />
                <VSelect
                  dense
                  clearable
                  outlined
                  hide-details
                  single-line
                  label="Sort"
                  item-text="title"
                  item-value="value"
                  class="search-field shrink mx-2"
                />
                <VBtn
                  depressed
                  width="120px"
                  height="38px"
                  class="px-3 mx-2"
                  style="border-radius: 10px; box-shadow: none; border: solid 1px rgba(0, 0, 0, 0.05);"
                >
                  <span
                    style="color: #808080; font-weight: 500; font-size: 14px;"
                  >
                    Filter by
                  </span>
                  <VSpacer />
                  <VIcon
                    small
                    color="default"
                  >
                    filter_list
                  </VIcon>
                </VBtn>
                <VTabs
                  fixed-tabs
                  height="38px"
                  background-color="transparent"
                  class="form-tabs toggle-view ml-2"
                >
                  <VTab>
                    Tiles
                  </VTab>
                  <VTab>
                    List
                  </VTab>
                </VTabs>
              </VCol>
            </VRow>
            <VRow class="mx-0">
              <VCol
                lg="9"
                md="9"
                sm="12"
                class="pl-0"
              >
                <VRow>
                  <VCol 
                    cols="6" 
                    class="pl-0"
                  >
                    <VBtn
                      height="82px"
                      width="100%"
                      class="no-shadow ml-2"
                      style="border: 1px dashed #D9D9D9; border-radius: 16px;"
                    >
                      <VList color="transparent">
                        <VListItem class="d-block">
                          <VListItemTitle style="color: #808080;">
                            Use Existing
                          </VListItemTitle>
                          <VListItemSubtitle
                            class="subtitle-1 py-1"
                            style="line-height: 14px; font-size: 12px !important;"
                          >
                            Select from existing forms
                          </VListItemSubtitle>
                        </VListItem>
                      </VList>
                    </VBtn>
                  </VCol>
                  <VCol cols="6">
                    <VBtn
                      height="82px"
                      width="100%"
                      class="no-shadow"
                      style="border: 1px dashed #D9D9D9; border-radius: 16px;"
                    >
                      <VList color="transparent">
                        <VListItem class="d-block">
                          <VListItemTitle style="color: #808080;">
                            Create Blank
                          </VListItemTitle>
                          <VListItemSubtitle
                            class="subtitle-1 py-1"
                            style="line-height: 14px; font-size: 12px !important;"
                          >
                            Create new application
                          </VListItemSubtitle>
                        </VListItem>
                      </VList>
                    </VBtn>
                  </VCol>
                </VRow>
              </VCol>
              <VCol 
                cols="3" 
                class="pr-0"
              >
                <VCard min-height="82px">
                  <VCardTitle
                    class="pt-3 pb-3"
                    style="border: none;"
                  >
                    <VRow>
                      <VCol
                        lg="8"
                        md="12"
                        sm="12"
                        class="d-flex align-center"
                      >
                        <span 
                          class="label-text" 
                          style="font-size: 16px;"
                        >
                          Huddles
                        </span>
                        <VIcon>
                          info
                        </VIcon>
                      </VCol>
                      <VCol
                        lg="4"
                        md="12"
                        sm="12"
                        class="d-flex justify-end align-center"
                      >
                        <VBtn
                          fab
                          disabled
                          width="26px"
                          min-width="26px"
                          height="26px"
                          class="wethrive"
                          style="border-radius: 10px;"
                          :block="$vuetify.breakpoint.md"
                        >
                          <VIcon
                            color="white"
                          >
                            mdi-plus
                          </VIcon>
                        </VBtn>
                      </VCol>
                    </VRow>
                  </VCardTitle>
                </VCard>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VContainer>
    </VCol>
    <VCol cols="12">
      <VAlert 
        type="info"
        color="rgba(251, 140, 26, 1)"
        class="mt-11 mx-12 mb-0 alert-icon"
        style="font-size: 16px;"
      >
        <span style="font-weight: 700;">FYI</span>: Newly created {{ featureName(modelType) }} are 
        <span style="font-weight: 700;">visible only to Team Owners and Admins</span>. You can invite program {{ featureName("Mentors") }} and {{ featureName("Students") }} in the next step.
      </VAlert>
    </VCol>
  </VRow>
</template>
<script>
import CreateProgramMixin from "@/mixins/CreateProgram";
import Card from "../Card";
export default {
  name: "ProgramsPreview",
  mixins: [CreateProgramMixin],
  components: {
    Card,
  },
};
</script>
<style lang="scss">
#preview-program {
  .alert-icon {
    .v-alert__icon.v-icon {
      padding-top: 12px;
    }
  }
}
</style>
<style lang="scss">
.card-overlay.v-overlay--active {
  backdrop-filter: none !important;
  .v-overlay__scrim {
    backdrop-filter: none !important;
  }
}
.main-view-overlay.v-overlay--active {
  backdrop-filter: blur(1.5px) !important;
  .v-overlay__scrim {
    backdrop-filter: blur(1.5px) !important;
  }
}
</style>