<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="checkout-page"
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow class="userMonetize">
      <SaveHeader
        :owner="this"
        :mode="mode"
      />
      <VCol style="flex: 1 1 auto">
        <VRow>
          <VCol cols="12">
            <span class="label">
              {{ featureName("Program", "singularize").capitalize() }} Name
            </span>
          </VCol>
          <VCol
            cols="12"
            class="d-flex align-center"
          >
            <h3 style="font-size: 18px; color: rgb(37, 37, 38);">
              {{ form.title }}
            </h3>
            <VBtn
              v-if="form.items_count === 1"
              class="ml-2"
              @click="dialogs.view = !dialogs.view"
            >
              <VIcon>mdi-eye</VIcon>
            </VBtn>
          </VCol>
          <VCol cols="12">
            <span class="label">
              {{ form.items_count > 1 ? "Choose the amount you wish to pay" : "Amount to be paid" }}
            </span>
          </VCol>
          <VCol cols="12">
            <VChipGroup
              v-if="form.items_count > 1"
              v-model="purchasePriceId"
              mandatory
              active-class="selected-price"
            >
              <VChip
                v-for="(item, index) in form.items"
                class="price-option px-8 my-0 mr-4"
                :key="index"
                :value="item.stripe_product_item_id"
              >
                ${{ item.price }}
              </VChip>
            </VChipGroup>
            <h3
              v-else
              style="font-size: 18px; color: rgb(37, 37, 38);"
            >
              ${{ form.items[0].price }}
            </h3>
          </VCol>
          <VCol cols="12">
            <span class="label">Title</span>
          </VCol>
          <VCol cols="12">
            <h4>
              {{ selectedPriceItem.title }}
            </h4>
          </VCol>
          <template v-if="selectedPriceItem.description">
            <VCol cols="12">
              <span class="label">Description</span>
            </VCol>
            <VCol cols="12">
              <p
                v-linkified
                v-html="selectedPriceItem.description"
                class="formatted content"
                :style="{
                  'line-height': 1.4, 
                  'color': '#000000',
                  'font-size': '14px'
                }"
              />
            </VCol>
          </template>
        </VRow>
        <StripeCheckout
          ref="checkoutRef"
          :pk="$root.env.VUE_APP_STRIPE_PUBLISHABLE_KEY"
          :session-id="sessionId"
          @loading="v => isLoading = v"
        />
      </VCol>
      <Dialog
        max-width="375px"
        :is-dialog-open="dialogs.view"
        :title="`${featureName('Program').singularize()} Details`"
        @toggle:dialog="dialogs.view = !dialogs.view"
      >
        <Details
          mode="dialog"
          :id="programId"
          class="py-4"
        />
      </Dialog>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import Details from "./Details";

export default {
  props: {
    programId: {
      type: [String, Number],
      default: null,
    }
  },
  mixins: [FormMixin],
  components: {
    StripeCheckout,
    SaveHeader,
    Details,
  },
  data() {
    return {
      isLoading: false,
      sessionId: null,
      purchasePriceId: null,
      currentURL: this.$root.env.VUE_APP_APP_URL + this.$route.fullPath,
      dialogs: {
        view: false,
      },
    };
  },
  watch: {
    isLoading(status) {
      if(status) {
        this.$bus.$emit("startFormLoading");
      } else {
        this.$bus.$emit("doneFormLoading");
      }
    },
  },
  computed: {
    selectedPriceItem() {
      if(this.purchasePriceId) {
        return this.form.items.find((item) => item.stripe_product_item_id === this.purchasePriceId);
      }
      return {};
    },
  },
  methods: {
    ...mapActions({
      doGetProduct: "product/getOne",
      doCreateCheckoutSession: "product/createCheckoutSession"
    }),
    onGet(id) {
      return this.doGetProduct(id).then((result) => {
        if(result.items_count === 1) {
          this.purchasePriceId = result.items[0].stripe_product_item_id;
        }
        return result;
      });
    },
    checkout() {
      let params = {
        mode: "payment",
        success_url: this.currentURL + "?state=success",
        cancel_url: this.currentURL + "?state=cancel",
        client_reference_id: this.$user.id,
        line_items: [{
          price: this.purchasePriceId,
          quantity: 1,
        }],
        metadata: {
          price_id: this.selectedPriceItem.id,
          product_id: this.id,
        }
      };
      this.isLoading = true;
      this.doCreateCheckoutSession(params).then((result) => {
        this.sessionId = result;
        this.$refs.checkoutRef.redirectToCheckout();
      });
    },
  }
}
</script>
<style lang="scss">
.userMonetize {
  .price-option {
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 900;
    color: #808080;
    background-color: #ececf1 !important;
  }
  .selected-price {
    box-shadow: 0 3px 8px 0 rgba(250, 36, 111, 0.2);
    border: solid 0.5px #fa246f;
    background-color: #ffffff !important;
    color: rgb(37, 37, 38);
  }
  .v-chip--active::before {
    background-color: white;
  }
  .col > .row > .col {
    padding: 6px 20px !important;
  }
}
</style>