<template>
  <VRow id="progress-program">
    <VCol
      cols="12"
      class="text-center"
    >
      <h1 class="main-title">
        Progress Makes Perfect
      </h1>
      <h2 
        class="main-subtitle"
        style="padding: 0px 160px;"
      >
        What’s your journey? From here you can create another {{ featureName(modelType, "singularize") }}, invite {{ featureName("students") }} to this one, or start adding content. Don’t worry, we’re here to help the whole way.
      </h2>
    </VCol>
    <VCol cols="12">
      <VRow>
        <VCol
          v-for="(step, index) in steps"
          :key="index"
        >
          <VCard 
            height="248px"
            class="step-card"
          >
            <VCardTitle class="border-none d-flex flex-column pt-5 mb-n5">
              {{ step.title }}
            </VCardTitle>
            <VCardText
              class="text-center"
              style="height: 108px; color: rgba(51, 51, 51, 1);"
            >
              {{ step.description }}
            </VCardText>
            <VCardActions class="justify-center">
              <VBtn 
                height="54px"
                width="160px"
                color="primary"
                class="rounded-lg black--text"
                style="box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);"
                @click="step.onClick"
              >
                <span style="font-size: 14px; font-weight: 600;">
                  {{ step.buttonText }}
                </span>
              </VBtn>
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </VCol>
    <VCol
      cols="12"
      class="text-center mt-4"
    >
      <span 
        class="template-link"
        @click="backToHome"
      >
        Nah, I’m good for now. Just take me home. >
      </span>
    </VCol>
  </VRow>
</template>
<script>
import CreateProgramMixin from "@/mixins/CreateProgram";
export default {
  name: "ProgramsProgress",
  mixins: [CreateProgramMixin],
  computed: {
    steps() {
      return [
        {
          enabled: true,
          title: `Build Out Your ${this.featureName(this.modelType, 'singularize')}`,
          description: `Get this ${this.featureName(this.modelType, 'singularize')} ready for action: add ${this.featureName('Lessons')}, ${this.featureName('Deliverables')}, ${this.featureName('Content')}, ${this.featureName('FAQs')}, and more.`,
          buttonText: "Add Content",
          onClick: () => {
            this.$emit("changeCurrentTab", 3);
          },
        },
        {
          enabled: true,
          title: `Invite ${this.featureName('Users')}`,
          description: `Ready to bring in collaborators and participants to view your ${this.featureName(this.modelType, 'singularize')} details? Use this flow to invite everyone.`,
          buttonText: "Manage Invites",
          onClick: () => {
            this.$router.push({
              name: "program.view",
              params: {
                id: this.item.id,
                team: this.$team.slug,
                openAddUsersDialog: true,
              },
              hash: "#dashboard",
            });
          },
        },
        {
          enabled: true,
          title: "Create Another",
          description: `Want to get all your ${this.featureName(this.modelType)} up and running first? Fire up the basics on a new ${this.featureName(this.modelType, 'singularize')}.`,
          buttonText: `Create a New ${this.featureName(this.modelType, 'singularize')}`,
          onClick: () => {
            this.$emit("changeCurrentTab", 0);
            this.$emit("resetForm");
          },
        },
      ].filter((step) => step.enabled === true);
    },
  },
  methods: {
    backToHome() {
      this.$emit("changeCurrentTab", 0);
      this.$nextTick(() => {
        this.$emit("toggle:form:dialog");
      })
    },
  },
};
</script>
<style lang="scss">
#progress-program {
  .step-card {
    .v-card__title {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .template-link {
    cursor: pointer; 
    color: rgba(51, 51, 51, 1);
    text-decoration: underline;
  }
}
</style>