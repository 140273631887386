<template>
  <VRow>
    <VCol
      cols="12"
      class="text-center"
    >
      <h1 class="main-title">
        Start A New {{ featureName(modelType, "singularize") }}
      </h1>
      <h2 
        class="main-subtitle"
        style="padding: 0px 24px;"
      >
        {{ featureName(modelType) }} serve as the core segments of your community, programs and services. Start with the basic info here, then we’ll guide you through the rest.
        <br>
        <span style="font-size: 15px; line-height: 20.25px;">
          <span class="red--text">*</span>
          required field
        </span>
      </h2>
    </VCol>
    <VCol cols="12">
      <div class="font-weight-black title mb-1">
        {{ featureName(modelType, "singularize") }} Title
        <span class="red--text ml-n1">*</span>
      </div>
      <TextField
        v-model="form.title"
        :error="$v.form.title.$error"
        :placeholder="`${featureName(modelType, 'singularize')} Name`"
        @blur="$v.$touch()"
        @input="$v.form.title.$touch()"
      />
    </VCol>
    <VCol
      cols="12"
      class="mt-n6"
    >
      <div class="font-weight-black title mb-1">
        Description
        <span class="red--text ml-n1">*</span>
      </div>
      <Editor
        v-model="form.content"
        auto-grow
        filled
        outlined
        class="content-editor"
        :error="$v.form.content.$error"
        :placeholder="`Brief ${featureName(modelType, 'singularize')} description (visible to all ${featureName('mentors')} and ${featureName('students')})`"
        @click="$v.$touch()"
        @blur="$v.$touch()"
      />
    </VCol>
    <VCol cols="12">
      <div class="font-weight-black title mb-1">
        {{ featureName(modelType, "singularize") }} Icon
      </div>
      <p style="color: #666666;">
        Use this (optional) icon to easily identify your {{ featureName(modelType, "singularize") }} throughout the app -- and to give it a little personality.
      </p>
      <EmojiAvatar
        alt-view
        ref="emojiAvatar"
        :item="form"
        @change="onChangeEmojiAvatar"
      />
    </VCol>
    <VCol cols="12">
      <div class="font-weight-black title mb-1">
        {{ featureName(modelType, "singularize") }} Location
      </div>
      <GeoSelectCity
        alt-view
        :countries="options.countries"
        :current-city="form.city"
        :current-city-id="form.city_id"
        :confirm-btn-style="{
          backgroundColor: '#2196f3',
          borderColor: '#2196f3',
          color: 'white !important',
        }"
        @citySelected="onCitySelected"
        @cityDetails="(city) => city_name = city.name"
        @stateDetails="(state) => state_name = state.name"
        @removeCity="form.city = form.city_id = null"
      />
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import CreateProgramMixin from "@/mixins/CreateProgram";
import EmojiAvatar from "@/components/Elements/Data/EmojiAvatar";

const regex = new RegExp(/(?<=>)(\s*[^\s]+\s*)+(?=<\/)/i);
const hasBlankContent = (content) => regex.test(content);

export default {
  name: "ProgramsCreateForm",
  mixins: [CreateProgramMixin],
  components: {
    EmojiAvatar,
  },
  data() {
    return {
      city_name: "",
      state_name: "",
      options: {
        calendar: [],
        reports: [],
        deliverables: [],
        supplements: [],
        primary_contents: [],
        countries: [],
        lessons: [],
        faqs: [],
      },
      defaultForm: {
        title: "",
        city_id: null,
        mentors: [],
        students: [],
        content: "",
        deliverables: [],
        reports: [],
        supplements: [],
        primary_contents: [],
        metadata: [],
        calendar_entries: [],
        faqs: [],
        lessons: [],
        files: [],
        visible_to: ['mentor', 'student'],
        background_color: null,
        emoji: null,
      },
    };
  },
  watch: {
    form: {
      handler: function(value) {
        if(this.city_name) {
          value.city = {
            name: this.city_name,
            parent: {
              name: this.state_name,
            },
          };
        }
        this.$emit("formDetails", value);
      },
      deep: true,
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      content: {
        required,
        hasBlankContent,
      },
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "program/getFormConfig",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onChangeEmojiAvatar(emoji, color) {
      this.form.background_color = color;
      this.form.emoji = emoji;
    },
    onCitySelected(cityId) {
      this.form.city_id = cityId;
    },
  },
};
</script>
